<template>
  <section class="applicationsUser" :key="reset">
    <h2 class="applicationsUser__title">Aplicaciones *</h2>
    <span ref="apps" class="applicationsUser__error"></span>
    <div class="applicationsUser__containerApps">
      <ApplicationCardRole v-for="app in userApps" :app="app" :selectApp="selectApp" :key="app.name" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: { ApplicationCardRole: () => import("./ApplicationCardRole.vue") },
  data() {
    return {
      userApps: [],
      reset: 1,
    };
  },
  computed: {
    ...mapGetters("applications", { getAppsInformation: "getAppsInformation" }),
    getAppsSelectedWithRole() {
      return this.userApps.filter(({ isSelected, role, key }) => isSelected && role && key !== "all");
    },
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
  },
  watch: {
    getAppsSelectedWithRole(newVal) {
      const rolesApp = newVal.map(({ key, role }) => ({ [key]: [role] }));
      const convertToObject = Object.assign({}, ...rolesApp);

      this.$store.state.user.dataUser.rolesApp = convertToObject;
      this.$store.state.user.dataUser.roles = convertToObject.hasOwnProperty("autologin")
        ? this.$store.state.user.dataUser.rolesApp["autologin"]
        : [];
    },
  },
  methods: {
    createAppsOptions() {
      const rolesApp = this.$store.state.user.dataUser.rolesApp;
      const apps = this.getAppsInformation;

      const priorityOrder = ["MySpace1A", "Reservas", "Registro", "administración", "Chat"];

      apps.sort((a, b) => {
        const priorityA = priorityOrder.indexOf(a.name);
        const priorityB = priorityOrder.indexOf(b.name);

        if (priorityA === -1 || priorityB === -1) {
          return 0;
        }
        return priorityA - priorityB;
      });

      const appsWithRole = Object.values(apps).map(({ name, icon, key }) => {
        const role = rolesApp?.[key]?.[0] || "";
        const isSelected = !!role;

        return { name, key, icon, role, isSelected };
      });

      const allApp = { name: "Todas", key: "all", role: "", icon: "/img/icons/apple-touch-icon-76x76.png", isSelected: false };

      allApp.role = appsWithRole.every((app) => app.role && app.role === appsWithRole[0].role) ? appsWithRole[0].role : "";
      allApp.isSelected = !!allApp.role;

      appsWithRole.push(allApp);

      return appsWithRole;
    },
    selectApp(app, isSelected, role) {
      const newRole = isSelected ? role : "";

      if (app === "all") {
        this.userApps.forEach((userApp) => {
          this.setDataApp({ app: userApp, isSelected, newRole });
        });
        return;
      }

      const appToChange = this.userApps.find((userApp) => app === userApp.key);
      this.setDataApp({ app: appToChange, isSelected, newRole });

      const allApp = this.userApps.find((userApp) => "all" === userApp.key);
      const appsWithRole = this.userApps.filter((userApp) => "all" !== userApp.key);

      const allRole = appsWithRole.every((app) => app.role && app.role === appsWithRole[0].role) ? appsWithRole[0].role : "";
      const allIsSelected = !!allRole;

      this.setDataApp({ app: allApp, isSelected: allIsSelected, newRole: allRole });
    },
    setDataApp({ app, isSelected = false, newRole = "" }) {
      app.isSelected = isSelected;
      app.role = newRole;
    },
    updateStyles(reference, response) {
      reference.style.display = response ? "none" : "block";
    },
    verifyMySpace(ref, data) {
      const reference = this.$refs[ref];
      const response = !!data;

      reference.innerText = !response ? `Seleccione un rol en MySpace` : "";

      this.updateStyles(reference, response);
      return response;
    },
    verifyNext() {
      const roleMySpace = this.getAppsSelectedWithRole.find(({ key }) => key === "autologin")?.role || "";
      const verifyMySpace = this.verifyMySpace("apps", roleMySpace);

      return verifyMySpace;
    },
  },
  mounted() {
    if (this.isEditUser) {
      this.verifyNext();
    }
  },
  beforeMount() {
    this.userApps = this.createAppsOptions();
  },
};
</script>

<style lang="scss">
.applicationsUser {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__containerApps {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 13px;
  }
  &__error {
    display: none;
    // margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
